<template>
  <section>
    <b-row>
      <b-col cols="7">
        <b-form-group>
          <div class="search-container">
            <Search class="icon" />
            <b-form-input
              id="patientOrGuide"
              v-model="filters.patientOrGuide"
              debounce="500"
              placeholder="Buscar paciente, número da guia ou item"
            />
          </div>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="2">
        <b-form-group>
          <multiselect
            id="situation"
            v-model="filters.situation"
            :options="situations"
            class="with-border"
            :show-labels="false"
            placeholder="Situações"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button id="btn-apply-filter" variant="outline-primary">Aplicar</b-button>
      </b-col> -->
    </b-row>
  </section>
</template>

<script>
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses'
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Search: () => import('@/assets/icons/search.svg'),
  },
  created() {
    this.getProfessionalsAuditDebounce = debounce(
      this.getProfessionalsAudit,
      500
    )
  },
  data() {
    return {
      getProfessionalsAuditDebounce: Function,
      clinic: getCurrentClinic(),
      professional: null,
      action: null,
      filters: {
        patientOrGuide: null,
        healthPlan: null,
        guideType: null,
        situation: null,
        invoice: null,
        lotNumber: null,
        procedures: null,
        professional: null,
        actionDescription: null,
        period: {
          start: null,
          end: null,
          type: 'create'
        }
      },
      guideTypes: [
        {
          label: 'Consulta',
          value: 'TissGuideConsultation'
        },
        {
          label: 'SP/SADT',
          value: 'TissGuideSpSadt'
        },
        {
          label: 'Honorários',
          value: 'TissGuideFee'
        },
      ],
      situations: [
        TissGuide.GUIDE_SITUATION_IN_PREPARATION,
        TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION,
        TissGuide.GUIDE_SITUATION_AUTHORIZED,
        TissGuide.GUIDE_SITUATION_DENIED,
        TissGuide.GUIDE_SITUATION_CANCELED,
        TissGuide.GUIDE_SITUATION_SENT,
        TissGuide.GUIDE_SITUATION_OPEN,
        TissGuide.GUIDE_SITUATION_PAYED,
        TissGuide.GUIDE_SITUATION_PARTIALLY_PAID,
        TissGuide.GUIDE_SITUATION_GLOSSED
      ],
      professionals: []
    }
  },
  computed: {
    actions() {
      if (!this.professional || !this.professional.value) return []
      return [
        'Selecionar',
        'Criou a guia',
        'Alterou o status da guia',
        'Conferiu a guia',
        'Anexou um arquivo',
        'Enviou o lote',
        'Fechou o lote',
        'Lote em recurso'
      ]
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedProcedures(newValue) {
      this.filters.procedures = newValue
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    },
    selectProfessional(professional) {
      this.professional = professional
      this.filters.professional = professional.label
      if (!professional.value) {
        this.filters.actionDescription = null
      }
    },
    async getProfessionalsAudit(query) {
      if (query.length > 2) {
        try {
          const response = await this.api.getProfessionalAudit(
            this.clinic.id,
            query
          )
          const professionals = response.data.map(audits => {
            return audits.person
          })
          this.professionals = professionals.map(professional => {
            return {
              label: professional.name,
              value: professional.id
            }
          })
          this.professionals.unshift({
            label: 'Selecionar',
            value: null
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.label-container {
  display: flex;
  flex-direction: row;

  label {
    margin-right: 20px;
  }

  div {
    font-size: 15px !important;
    margin-right: 15px;
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  position: relative;
  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    height: 100%;
    margin-left: 10px;
  }

  input {
    padding-left: 40px !important;
  }
}
#btn-apply-filter{
  background: var(--surface-tertiary, #E7ECFE);
  border-color: var(--surface-tertiary, #E7ECFE);
  width: 100%;
}
</style>
